
import { useAuth0 } from "~/composables/useAuth0";

export default {
  layout: "login",

  data() {
    return {
      loginError: false,

      loginLoading: false,
    };
  },

  methods: {
    logIn() {
      useAuth0().loginWithRedirect();

      this.amplitudeLogEvent("Login");
    },

    amplitudeLogEvent(event) {
      this.$amplitude.track({ event_type: event });
    },
  },
};
